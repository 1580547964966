import React, { useState } from "react";
import { I18nextProvider } from "react-i18next";
import { connect } from "react-redux";
import i18n from "../../i18n/i18n";
import styles from "./index.module.scss";
import SEOComponent from "../../components/Shared/Seo";
import NavMenu from "../../components/nav-menu/NavMenu";
import Footer from "../../components/main-page/sections/footer/Footer";
import GroupLegal from "../../components/group-legal/GroupLegal";
import Tabs from "../../components/marktdaten/Tabs";
import Filter from "../../components/marktdaten/Filter";
import PriceChart from "../../components/marktdaten/PriceChart";
import MarketInsights from "../../components/main-page/sections/marketInsights/MarketInsights";
import OptimizeScrap from "../../components/optimize-scrap/OptimizeScrap";
import IncreaseRevenue from "../../components/main-page/sections/increaseRevenue/IncreaseRevenue";

const Material = () => {
  const [tab, setTab] = useState("chart");
  const homeUrl = "";

  return (
    <div>
      <I18nextProvider i18n={i18n} defaultNS={"default"}>
        <SEOComponent page="marktdaten" />
        <NavMenu />

        <div className={styles.breadcrumb}>
          <a href={homeUrl}>Home</a>
          <span className={styles.separator}>/</span>
          <span> Marktdaten</span>
        </div>

        <div className={styles.chartData}>
          <h1 className={styles.chartTitle}>
            Aktuelle Kupferpreisentwicklungen
          </h1>
          <Tabs tab={tab} setTab={setTab} />
          <Filter />

          {tab === "chart" && <PriceChart />}
        </div>

        <OptimizeScrap />

        <Footer />
        <GroupLegal />
      </I18nextProvider>
    </div>
  );
};

export default React.memo(Material);
